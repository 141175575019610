// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    app: 'evduty',
    production: false,
    apiBasicKey: 'UEVCUzJIZk53SkRwNWNwaGthV21rcXJEQTlxSG1aMzc6REJWNWtqZExReWpjODRGS3VKSko4a0FBdndGNjhRc0Q=',
    apiUrl: 'https://api-dev.evduty.net',
    apiKey: 'PEBS2HfNwJDp5cphkaWmkqrDA9qHmZ37',
    apiSecret: 'DBV5kjdLQyjc84FKuJJJ8kAAvwF68QsD',
    cookiePrefix: 'evduty',
    gridLimit: 10,
    whitelistedDomains: ['api-dev.evduty.net'],
    showOffers: true
};
